<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px;"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>

    <div class="mx-3" />

    <v-btn
      class="ml-2"
      min-width="0"
      text
    >
      <v-icon>mdi-home-account</v-icon>
      <strong class="display-1 text-dark"> {{currentUser.FirstName}}'s  Goose </strong> 
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            :content="notifications.length"
            :value="notifications.length"
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>{{notifications.length}}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            two-line
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-content @click="gotoMessage(n.seller_id, n.supplier_id)">
              <v-list-item-title v-text="n.sender" />
              <v-list-item-subtitle v-text="n.message"></v-list-item-subtitle>
            </v-list-item-content>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <template v-for="(p, i) in profile">
          <v-divider
            v-if="p.divider"
            :key="`divider-${i}`"
            class="mb-2 mt-2"
          />

          <app-bar-item
            v-else
            :key="`item-${i}`"
            href="/account"
          >
            <v-list-item-title v-text="p.title" />
          </app-bar-item>





          
        </template>

        <template>
          <div @click="onLogout" >
           <app-bar-item @click="onLogout"
         
          >
            <v-list-item-title @click="onLogout" >  Log OUT </v-list-item-title>
          </app-bar-item>
          </div>
        </template>

      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'
import { LOGOUT } from "@/core/services/store/auth.module";
import gql from 'graphql-tag'
  // Utilities
import { mapGetters,mapState, mapActions, mapMutations } from "vuex";

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
             ...mapGetters([
      "isAuthenticated",
      'currentUser'
    ]),
      ...mapState(['drawer']),
    },
    data: () => ({
      notifications: [],
      profile: [
        { title: 'Edit Profile',
        path: '/account' },
       
        { divider: true },
        
      ],
    }),
    apollo: {
      unreadChats: {
        query: gql`
          query UnreadChatsQuery($supplier_id: Int!) {
            unreadChats: unreadchats(supplier_id: $supplier_id) {
              id
              seller_id
              supplier_id
              message
              sender
              date
              is_seller
            }
          }
        `,
        variables() {
          return {
            supplier_id: parseInt(this.$store.state.auth.user.company_relation)
          }
        },
        client: 'chatClient',
        skip() {
          return true
        }
      },
      unreadSellerChats: {
        query: gql`
          query UnreadChatsQuery($seller_id: Int!) {
            unreadSellerChats: unreadsellerchats(seller_id: $seller_id) {
              id
              seller_id
              supplier_id
              message
              sender
              date
              is_seller
            }
          }
        `,
        variables() {
          return {
            seller_id: parseInt(this.$store.state.auth.user.company_relation)
          }
        },
        client: 'chatClient',
        skip() {
          return true
        }
      },
    },
    watch: {
      chats() {
        setTimeout(() => {
          this.updateUnreadChats();
        }, 500);
      },
    },
    mounted() {
      let self = this;
      const messageSentQuery = gql`subscription {
          messageSent {
            id
            seller_id
            supplier_id
            message
            sender
            date
            is_seller
          }
        }
      `;
      const messageSentObserver = this.$apollo.subscribe({
        query: messageSentQuery,
        client: 'chatClient',
      });
      messageSentObserver.subscribe({
        next (data) {
          self.updateUnreadChats();
        },
        error (error) {
          console.error(error)
        },
      })

      const updateReadQuery = gql`subscription {
          updateReadMsg {
            seller_id
            supplier_id
          }
        }
      `;
      const updateReadObserver = this.$apollo.subscribe({
        query: updateReadQuery,
        client: 'chatClient',
      });

      updateReadObserver.subscribe({
        next (data) {
          self.updateUnreadChats();
        },
        error (error) {
          console.error(error)
        },
      })

      setTimeout(() => {
        this.updateUnreadChats();
      }, 1000);
    },
    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      gotoMessage(seller_id, supplier_id) {
        if(this.$acl.check('isSupplier')) {
          this.$router.push('/supplier/messaging/thread/'+seller_id);
        } else if(this.$acl.check('isSeller')) {
          this.$router.push('/seller/messaging/thread/'+supplier_id);
        }
      },
      async updateUnreadChats() {
        if(this.$acl.check('isSupplier')) {
          this.$apollo.queries.unreadChats.skip = false;
          await this.$apollo.queries.unreadChats.refetch();
          this.notifications = this.unreadChats;
        } else if(this.$acl.check('isSeller')) {
          this.$apollo.queries.unreadSellerChats.skip = false;
          let res = await this.$apollo.queries.unreadSellerChats.refetch();
          this.notifications = this.unreadSellerChats;
        }
      },
      onLogout() {
        this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({ path: "/pages/login" }));
      },
    },
  }
</script>
